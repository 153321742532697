// Responsive utilities

//
// More easily include all the states for responsive-utilities.less.
.responsive-visibility() {
  display: block !important;
  tableand  { display: table !important; }
  trand     { display: table-row !important; }
  th&,
  tdand     { display: table-cell !important; }
}

.responsive-invisibility() {
  display: none !important;
}
